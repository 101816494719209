.custom-card1 {
    width: 7.3rem; /* Fixed width */
    min-height: 6rem; /* Minimum height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  
  @media (max-width: 767.98px) { /* Small devices (landscape phones, 576px and up) */
    .text-end-sm {
      text-align: end;
    }
  }
  
  @media (min-width: 768px) { /* Medium devices (tablets, 768px and up) */
    .text-end-sm {
      text-align: start;
    }
  }
  
    